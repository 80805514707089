<template>
  <div class="text-gray-800 border-t-4 border-blue-500 modal">
    <div class="px-4 py-5 bg-white border-b border-gray-200 sm:px-6">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
        Asset Description
      </h3>
    </div>

    <div class="px-8 py-4 modal-content bg-neutral-gray">
      <form
        :class="{ 'error' : form.errors.get('form'), 'error' : formFormHasErrors }"
        @submit.prevent="submit"
      >
        <div class="my-2">
          <label
            for="about"
            class="block text-sm font-medium leading-5 text-gray-700"
          >
            Asset Description
          </label>
          <div class="relative mt-1 rounded shadow-sm">
            <textarea
              ref="input"
              v-model="form.description"
              class="block w-full rounded form-textarea sm:text-sm sm:leading-5"
              rows="6"
              placeholder="Enter an asset description.."
              name="description"
              autofocus
            />
          </div>
          <p class="my-2 text-sm text-right text-gray-600">
            Markdown formatting supported.
          </p>
        </div>
        <div class="flex items-center justify-end mt-6 btn-group">
          <button
            class="btn"
            type="button"
            @click="$emit('close')"
          >
            Cancel
          </button>
          <button
            class="btn primary"
            type="button"
            @click.prevent="submit"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
const TurndownService = require('turndown').default

export default {

  name: 'AssetDescription',

  props: {
    asset: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      turndownService: new TurndownService(),
      form: new SparkForm({
        description: null
      }),
      formFormHasErrors: false
    }
  },
  computed: {
    readyToSubmit () {
      return this.form.name !== ''
    }
  },

  watch: {
    asset: {
      handler (asset) {
        this.form.description = this.turndownService.turndown(this.asset.description)
      },
      immediate: true
    }
  },

  mounted () {
    this.$refs.input.focus()
  },

  methods: {
    submit () {
      if (this.readyToSubmit) {
        let payload = {
          description: this.form.description
        }

        this.formFormHasErrors = false
        this.form.startProcessing()
        this.$store.dispatch('assets/update', {
          asset: this.asset,
          payload: payload
        })
          .then(result => {
            this.form.finishProcessing()
            this.$emit('close')
            alert.success('Asset details updated')
          })
          .catch(response => {
            console.log(response)
            alert.error('Failed to update asset')
            this.formFormHasErrors = true
            this.form.setErrors(response.data)
            this.form.finishProcessing()
          })
      }
    }
  }
}
</script>
